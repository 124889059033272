import { computed } from 'vue'

export default function () {
  const onBrowser = computed(() => {
    return typeof window !== 'undefined'
  })

  return {
    onBrowser
  }
}
