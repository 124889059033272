<script setup></script>

<template>
	<div class="relative flex flex-shrink-0 select-none place-content-center">
		<a href="https://fezz.it" class="grid select-none place-items-center">
			<img src="assets/fezz_logo.png" class="z-50 h-20 w-20" alt="Fezz Logo" />
			<div
				class="absolute z-10 w-16 h-16 delay-500 rounded-full shadow-xl opacity-25 shadow-secondary bg-secondary"
			></div>
		</a>
	</div>
</template>
