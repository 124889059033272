<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";

const isOpen = ref<boolean>(false);
const isScrolled = ref<boolean>(false);

const handleScroll = () => {
    isScrolled.value = window.scrollY > 100;
};

onMounted(() => {
    window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
    <div
        class="fixed z-50 transition-all delay-200 navbar"
        :class="{
            'bg-transparent': !isScrolled && !isOpen,
            'bg-base-300': isScrolled || isOpen,
            'grid text-center': isOpen,
        }"
    >
        <div
            class="p-2"
            :class="{
                'flex-1 navbar-start': !isOpen,
                'w-full p-4 grid place-items-center': isOpen,
            }"
        >
            <NavigationNavBrand />
        </div>
        <div
            class="flex-none gap-6 px-6 navbar-center"
            :class="{ 'hidden lg:flex': !isOpen, grid: isOpen }"
        >
            <NavigationNavMenu :is-open="isOpen" />
            <div
                :class="{
                    'grid grid-cols-2 gap-6': !isOpen,
                    'flex justify-between m-4': isOpen,
                }"
            >
                <NavigationNavThemeSwitch :end="!isOpen" />
                <NavigationNavLangSwitch :end="true" />
            </div>
        </div>
        <label
            tabindex="0"
            class="btn btn-ghost bg-base-300"
            :class="{ 'lg:hidden': !isOpen }"
            @click="isOpen = !isOpen"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                />
            </svg>
        </label>
    </div>
    <!--nav
        class="relative top-0 z-50 flex flex-wrap items-center justify-between w-full p-4 bg-gray-100 shadow shadow-lg dark:bg-gray-900"
    >
        <NavBrand/>
        <NavToggle v-model="isOpen"/>
        <NavMenu :is-open="isOpen"/>
    </!--nav-->
</template>
