<script setup lang="ts">
	import { ref, withDefaults } from "vue";
	import useBrowser from "@/composables/useBrowser";
	const { onBrowser } = useBrowser();
	interface Props {
		end: boolean;
	}

	const props = withDefaults(defineProps<Props>(), {
		end: false,
	});

	import { library } from "@fortawesome/fontawesome-svg-core";

	import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
	import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import type { Theme } from "~/types/Theme";

	library.add({
		faLightbulb,
	});

	const switchTheme = (theme: Theme) => {
		if (typeof window !== "undefined") {
			if (document) {
				document?.querySelector("html")?.setAttribute("data-theme", theme.code);
			}
			if (localStorage) {
				localStorage.setItem("theme", theme.code);
			}
		}
		// if(document !== undefined){
		// document?.querySelector("html")?.setAttribute("data-theme", theme.code);
		// }
		// localStorage.setItem("theme", theme.code);
		currentTheme.value = theme.code;
	};

	// const getTheme = () => {
	//     return (
	//         // (document !== undefined) ? document.querySelector("html")?.getAttribute("data-theme") : undefined ||
	//         localStorage.getItem("theme") || "light"
	//     );
	// };
	const getTheme = () => {
		if (typeof window !== "undefined") {
			if (document) {
				return (
					document.querySelector("html")?.getAttribute("data-theme") ||
					localStorage.getItem("theme") ||
					"light"
				);
			} else {
				return localStorage.getItem("theme") || "light";
			}
		} else {
			return "light"; // Fallback nel caso in cui non ci sia window (ad esempio, SSR)
		}
	};

	const currentTheme = ref(getTheme());

	const themes = ref<Theme[]>([
		{
			name: "Dark",
			code: "dark",
			dark: true,
		},
		{
			name: "Coffee",
			code: "coffee",
			dark: true,
		},
		{
			name: "Sunset",
			code: "sunset",
			dark: true,
		},
		{
			name: "Luxury",
			code: "luxury",
			dark: true,
		},
		{
			name: "Light",
			code: "light",
			dark: false,
		},
		{
			name: "Cupcake",
			code: "cupcake",
			dark: false,
		},
		{
			name: "Retro",
			code: "retro",
			dark: false,
		},
		{
			name: "Corporate",
			code: "corporate",
			dark: false,
		},
	]);
</script>
<template>
	<div class="dropdown" :class="{ 'dropdown-end': props.end }">
		<label
			tabindex="0"
			class="grid btn btn-ghost btn-circle bg-base-100 hover:bg-base-200 text-base-content avatar place-items-center ring ring-neutral ring-offset-base-100 ring-offset-2"
		>
			<FontAwesomeIcon
				v-if="onBrowser"
				icon="fa-regular fa-lightbulb"
				size="xl"
			/>
		</label>
		<div
			tabindex="200"
			class="mt-3 shadow z-[10] card card-compact dropdown-content w-52 bg-base-100"
		>
			<div class="card-body">
				<h5 class="p-2 text-lg text-center">Dark Themes</h5>
				<div class="grid grid-cols-2 gap-4">
					<label
						v-for="theme in themes.filter((th) => th.dark)"
						:data-theme="theme.code"
						tabindex="0"
						class="grid overflow-hidden text-xs btn btn-lg btn-ghost bg-base-300 hover:bg-base-200 text-base-content avatar place-items-center ring ring-offset"
						:class="{
							'ring-primary ring-offset-primary': theme.code === currentTheme,
							'ring-neutral ring-offset-neutral': theme.code !== currentTheme,
						}"
						@click="switchTheme(theme)"
					>
						{{ theme.name }}
					</label>
				</div>
				<h5 class="p-2 text-lg text-center">Light Themes</h5>
				<div class="grid grid-cols-2 gap-4">
					<label
						v-for="theme in themes.filter((th) => !th.dark)"
						:data-theme="theme.code"
						tabindex="0"
						class="grid overflow-hidden text-xs btn btn-lg btn-ghost bg-base-300 hover:bg-base-200 text-base-content avatar place-items-center ring ring-offset"
						:class="{
							'ring-primary ring-offset-primary': theme.code === currentTheme,
							'ring-neutral ring-offset-neutral': theme.code !== currentTheme,
						}"
						@click="switchTheme(theme)"
					>
						{{ theme.name }}
					</label>
				</div>
			</div>
		</div>
	</div>
</template>
