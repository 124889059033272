<script setup lang="ts">
	const props = defineProps<{
		routeName: string;
	}>();
</script>

<template>
	<li>
		<NuxtLink
			:to="{ name: props.routeName + '___' + $i18n.locale }"
			class="nav-link uppercase text-md btn font-semibold"
			active-class="active"
		>
			<slot />
		</NuxtLink>
	</li>
</template>
